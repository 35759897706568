

.header-container {
    
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}



