
.form-wrapper {

    width: 100vh;
    height: 50vh;
    margin: 1em auto;
    margin-bottom: 30em;
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 1.5em;
        margin-bottom: 2em;
    }

    .contacts-header {
        
        margin-bottom: 2em;
        
    }
    
}

.contact-form {
    min-width: 80%;
    height: auto;
    background:linear-gradient(var(--main-background-color));
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-color);

    .messageLabel {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        
        

        textarea {
            
            color: var(--text-color);
            margin: 1em auto;
            background-color: transparent;
            border-radius: 0%;
            border: 1px solid var(--text-color);
            display: flex;
            padding: 0.5em;

        }
    }

    button {
        border: 1px solid var(--text-color);
        border-radius: 0%;
        font-family: var(--text-style);
        text-transform: uppercase;
        background-color: transparent;
        color: var(--text-color);
        cursor: pointer;
    }

    input {
        color: var(--text-color);
        margin: 1em auto;
        background-color: transparent;
        border-radius: 0%;
        border: 1px solid var(--text-color);
        display: flex;
        padding: 0.5em;

    }
}


@media (max-width:534px) {
    .form-wrapper {
        height: 30vh;
    }
}